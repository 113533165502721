import {
  ActionIcon,
  Button,
  Drawer,
  Flex,
  Loader,
  Modal,
  Skeleton,
  TextInput,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { CameraCard } from '../../components/CameraCard/CameraCard';
import { Camera } from '../../data/camera-data';
import { base64ToSrc } from '../../helpers/base64-helper';
import { useDataFetcher } from '../../hook/use-data-fetcher';
import { useDialog } from '../../hook/use-dialog';
import { customerService } from '../../services/api-client/customer-service';
import { MessageStatus } from '../../services/dialog';
import { withAuthProtection } from '../../services/protect-route-element';

// Page component

const Page = () => {
  const notification = useDialog();
  const [modalOpened, { open: modalOpen, close: modalClose }] =
    useDisclosure(false);
  const [drawerOpened, { open: drawerOpen, close: drawerClose }] =
    useDisclosure(false);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [fetchCameras, setFetchCameras] = useState<boolean>(true);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [cameraId, setCameraId] = useState<string>('');
  const [triggerImageCaptureLoading, setTriggerImageCaptureLoading] = useState<
    Record<string, boolean>
  >({});
  const [cameras, isLoading] = useDataFetcher<Camera[]>({
    serviceCall() {
      return customerService.getCameras();
    },
    dependencies: [fetchCameras],
    conditionForExecution: fetchCameras === true,
  });

  useEffect(() => {
    setFetchCameras(false);
    const automaticTriggerInterval = setInterval(
      () => {
        setFetchCameras(true);
      },
      5 * 60 * 1000
    );

    return () => {
      clearInterval(automaticTriggerInterval);
    };
  }, [cameras]);

  const handleManualTrigger = async (cameraId: string) => {
    try {
      setTriggerImageCaptureLoading(prevState => ({
        ...prevState,
        [cameraId]: true,
      }));
      await customerService.triggerImageCapture(cameraId);
      notification.notify(
        MessageStatus.Success,
        'Trigger',
        'Image capture triggered successfully'
      );

      modalOpen();
      setImageLoading(true);
      const intervalId = setInterval(async () => {
        const imageResult = await customerService.getLatestImage(cameraId);
        if (imageResult.imageBuffer) {
          const imageSrc = base64ToSrc(imageResult.imageBuffer);
          clearInterval(intervalId);
          setImgSrc(imageSrc);
          setImageLoading(false);
        }
      }, 5000);

      setTimeout(() => {
        clearInterval(intervalId);
        setImageLoading(false);
      }, 25000);
    } catch (error) {
      notification.notify(
        MessageStatus.Error,
        'Trigger',
        'Failed to trigger image capture'
      );
      console.log(error);
    } finally {
      setTriggerImageCaptureLoading(prevState => ({
        ...prevState,
        [cameraId]: false,
      }));
    }
  };

  const handleAddCamera = async () => {
    try {
      setCreateLoading(true);
      await customerService.createCamera(cameraId);
      notification.notify(
        MessageStatus.Success,
        'Add Device',
        'Device added successfully'
      );
      drawerClose();
    } catch (error) {
      notification.notify(
        MessageStatus.Error,
        'Add Device',
        `Failed to add device: ${(error as any).response.data.errors[0]}`
      );
      console.log(error);
    } finally {
      setCreateLoading(false);
      setFetchCameras(true);
    }
  };

  const handleDeleteCamera = async (cameraId: string) => {
    try {
      setFetchCameras(false);

      await customerService.deleteCamera(cameraId);
      notification.notify(
        MessageStatus.Success,
        'Delete Device',
        'Device removed successfully'
      );
      drawerClose();
    } catch (error) {
      notification.notify(
        MessageStatus.Error,
        'Delete Device',
        `Failed to remove device: ${(error as any).response.data.errors[0]}`
      );
      console.log(error);
    } finally {
      setFetchCameras(true);
    }
  };

  const getContent = () => {
    if (isLoading) {
      return Array.from(Array(10).keys()).map(index => {
        return (
          <Skeleton
            key={index}
            height={200}
            w={'15.875rem'}
            radius={'1.25rem'}
          />
        );
      });
    }
    return cameras?.map(camera => {
      return (
        <CameraCard
          key={`camera-${camera.id}`}
          id={camera.id}
          image={base64ToSrc(camera.pictures[0]?.image ?? ' ')}
          isLoading={triggerImageCaptureLoading[camera.id]}
          onTrigger={id => handleManualTrigger(id)}
          onDelete={id => {
            handleDeleteCamera(id);
          }}
        />
      );
    });
  };

  return (
    <Flex direction={'column'}>
      <Flex justify={'space-between'} align={'center'}>
        <Title order={1}>Devices</Title>
        <ActionIcon onClick={drawerOpen} size={'lg'}>
          <MdAdd size={'18px'} />
        </ActionIcon>
        <Drawer
          opened={drawerOpened}
          onClose={drawerClose}
          position="right"
          title="Add Device"
        >
          <Flex direction={'column'} gap={'1rem'}>
            <TextInput
              label="Device ID"
              placeholder="camera-id"
              onChange={event => {
                setCameraId(event.currentTarget.value);
              }}
            />

            <Button
              disabled={!cameraId}
              onClick={handleAddCamera}
              loading={createLoading}
            >
              Add
            </Button>
          </Flex>
        </Drawer>
      </Flex>
      <Flex gap={'1rem'} mt={'1rem'} wrap={'wrap'}>
        {getContent()}
      </Flex>
      <Modal size={'lg'} opened={modalOpened} onClose={modalClose} centered>
        <Flex align={'center'} justify={'center'}>
          {imageLoading ? (
            <Loader />
          ) : (
            <>
              {imgSrc ? (
                <img src={imgSrc} alt="Latest" width={'100%'} height={'auto'} />
              ) : (
                <span>No image found, try again later</span>
              )}
            </>
          )}
        </Flex>
      </Modal>
    </Flex>
  );
};

export const DevicesList = withAuthProtection(Page);
