import { ActionIcon, Avatar, Flex, FlexProps, Menu, Text } from '@mantine/core';
import { FunctionComponent, ReactNode, useState } from 'react';
import { BiLogOut, BiUser } from 'react-icons/bi';
import { MdArrowDropDown, MdMenu } from 'react-icons/md';
import './ToolBar.scss';

export interface AvatarMenuItem {
  displayName: string;
  icon: ReactNode;
  onClick?: () => void;
}
export interface ToolBarProps extends FlexProps {
  name?: string;
  organization?: string;
  profileImage?: string;
  avatarMenuItems?: AvatarMenuItem[];
  expanded?: boolean;
  onLogoutClick?: () => void;
  onMenuClick?: () => void;
  onSearchClick?: (value: string) => void;
}

export const ToolBar: FunctionComponent<ToolBarProps> = props => {
  const [searchInput, setSearchInput] = useState<string>('');

  return (
    <Flex
      w={'100%'}
      h={'100%'}
      bg={'#ffffff'}
      align={'center'}
      justify={'space-between'}
      p={'1rem 2rem'}
      gap={'md'}
      classNames={{ root: 'ToolBar' }}
      {...props}
    >
      <Flex align={'center'} gap={'sm'}>
        {!props.expanded && (
          <ActionIcon
            variant="transparent"
            color="#1F374E"
            size={'md'}
            onClick={() => props.onMenuClick?.()}
          >
            <MdMenu size={'24px'} />
          </ActionIcon>
        )}
      </Flex>
      <Menu
        classNames={{
          dropdown: 'Dropdown',
          item: 'Item',
          itemSection: 'ItemSection',
        }}
        shadow="lg"
        width={170}
        position="bottom-end"
        offset={7}
      >
        <Menu.Target>
          <Flex
            className="AvatarContainer"
            align={'center'}
            c="#1F374E"
            gap={8}
          >
            <Avatar
              variant="filled"
              size={'42px'}
              color="#1F374E"
              alt={props.name}
              src={props.profileImage}
            >
              <BiUser size={'24px'} />
            </Avatar>
            <Flex align={'center'}>
              <Flex direction="column">
                <Text>{props.name}</Text>
                <Text size="xs" color="gray">
                  {props.organization}
                </Text>
              </Flex>
              <MdArrowDropDown size={'24px'} color="#6085A7" />
            </Flex>
          </Flex>
        </Menu.Target>
        <Menu.Dropdown>
          {props.avatarMenuItems &&
            props.avatarMenuItems.map(item => {
              return (
                <Menu.Item
                  leftSection={item.icon}
                  onClick={() => {
                    item.onClick?.();
                  }}
                >
                  {item.displayName}
                </Menu.Item>
              );
            })}
          {props.avatarMenuItems && <Menu.Divider />}
          <Menu.Item
            leftSection={<BiLogOut size={'27px'} />}
            onClick={() => props.onLogoutClick?.()}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Flex>
  );
};
