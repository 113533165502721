export const configuration = {
  cedApiKey: "806dc6192afa983765aaadb0387f8408672416f9b0cd02000cadcceeba671406626290cee26bf2a0e17335ff734c97123cf7",
  environment: "production",
  version: "1.0.12",
  backend: {
    hostingUrl: "https://vizion-api-prod.tools.cosmosrobotics.com",
  },
  userRegistry: {
    domain: "cosmosrobotics-prod.us.auth0.com",
    audience: "https://vizion-platform-prod-auth.com",
    spaClientId: "iuzJYjyHmoqdowPNuJ8lDCWHeD76cESf",
  },
};