import { Route, Routes } from 'react-router-dom';
import { DevicesList } from './pages/DevicesList/DevicesList';
import { EmptyLayout } from './pages/EmptyLayout/EmptyLayout';
import { LoginCallbackPage } from './pages/LoginCallbackPage/LoginCallbackPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import { MainLayout } from './pages/MainLayout/MainLayout';
import { SignupPage } from './pages/SignupPage/SignupPage';

export const Kernel = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route path="home" element={<DevicesList />} />
        <Route index element={<DevicesList />} />
      </Route>
      <Route path="/" element={<EmptyLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="login-callback" element={<LoginCallbackPage />} />
        <Route path="signup" element={<SignupPage />} />
      </Route>
    </Routes>
  );
};
