import {
  Button,
  Center,
  Flex,
  FlexProps,
  Loader,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { FunctionComponent } from 'react';
export interface RegisterCredentials {
  email: string;
  username: string;
  password: string;
}

export interface SignupFormProps extends FlexProps {
  passwordRegex?: RegExp;
  passwordErrorMessage?: string;
  isLoading?: boolean;
  failedSignInMessage?: string;
  onRegisterClick?: (registerCredentials: RegisterCredentials) => void;
  onSignInWithGoogleClick?: () => void;
  onBackToSignInClick?: () => void;
}

export const SignupForm: FunctionComponent<SignupFormProps> = props => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = props.passwordRegex ?? /^.{8,}$/;

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      email: '',
      username: '',
      password: '',
    },
    validate: {
      email: value => (emailRegex.test(value) ? null : 'Invalid email'),
      password: value =>
        passwordRegex.test(value)
          ? null
          : props.passwordErrorMessage ??
            'Password must be at least 8 characters',
    },
  });

  return (
    <form onSubmit={form.onSubmit(values => props.onRegisterClick?.(values))}>
      {props.failedSignInMessage && (
        <Text c={'red'}>{props.failedSignInMessage}</Text>
      )}
      <Flex direction={'column'} gap={'0.75rem'}>
        <TextInput
          type="email"
          disabled={props.isLoading}
          placeholder="Enter email"
          {...form.getInputProps('email')}
        ></TextInput>
        <TextInput
          type="text"
          disabled={props.isLoading}
          placeholder="Enter username"
          {...form.getInputProps('username')}
        ></TextInput>
        <PasswordInput
          disabled={props.isLoading}
          placeholder="Enter password"
          {...form.getInputProps('password')}
        ></PasswordInput>

        {props.isLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Flex gap={'0.75rem'} direction={'column'}>
            <Button
              variant="light"
              type="submit"
              disabled={
                !form.isValid('email') ||
                !form.isValid('password') ||
                !form.values.username
              }
            >
              Register
            </Button>
            <Button
              variant="light"
              className="btnSignInGoogle"
              onClick={() => props.onSignInWithGoogleClick?.()}
            >
              Sign in with Google
            </Button>
            <Button
              variant="light"
              className="btnSignInGoogle"
              onClick={() => props.onBackToSignInClick?.()}
            >
              Already have an Account
            </Button>
          </Flex>
        )}
      </Flex>
    </form>
  );
};
