import {
  ActionIcon,
  Button,
  Flex,
  Menu,
  Modal,
  Skeleton,
  Text,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FunctionComponent } from 'react';
import { MdOutlineMoreHoriz } from 'react-icons/md';
import { Card } from '../commons/Card/Card';
import './CameraCard.scss';
export interface CameraCardProps {
  id: string;
  image?: string;
  isLoading?: boolean;
  onTrigger?: (id: string) => void;
  onDelete?: (id: string) => void;
}

export const CameraCard: FunctionComponent<CameraCardProps> = props => {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedDelete, { open: openDelete, close: closeDelete }] =
    useDisclosure(false);

  const handleDelete = () => {
    props.onDelete?.(props.id);
    closeDelete();
  };
  return (
    <Card
      miw={'15.875rem'}
      maw={'15.875rem'}
      className="CameraCardWrapper"
      bg={'#ffffff'}
      shadow="md"
    >
      <Flex direction={'column'} gap={'1rem'}>
        <Flex justify={'space-between'} align={'center'}>
          <Title order={3}>{props.id}</Title>
          <Menu shadow="md">
            <Menu.Target>
              <ActionIcon variant="transparent" color="dark">
                <MdOutlineMoreHoriz size={'18px'} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item color="red" onClick={openDelete}>
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Flex>
        <Button
          loading={props.isLoading}
          onClick={() => props.onTrigger?.(props.id)}
        >
          Trigger
        </Button>
        {props.isLoading && <Skeleton height={200} radius={'1.25rem'} />}
        {!props.isLoading && (
          <img
            className="PreviewImage"
            src={props.image}
            alt="products"
            onClick={open}
          />
        )}
        <Modal size={'lg'} opened={opened} onClose={close}>
          <img
            width={'100%'}
            height={'auto'}
            src={props.image}
            alt="products"
          />
        </Modal>
        <Modal
          opened={openedDelete}
          onClose={closeDelete}
          title="Delete Confirmation"
        >
          <Flex direction={'column'} gap={'1rem'}>
            <Text>Are you sure you want to delete this device?</Text>
            <Text c={'red'}>{props.id}</Text>
            <Flex justify={'flex-end'} gap={'1rem'}>
              <Button onClick={closeDelete}>Cancel</Button>
              <Button color="red" onClick={handleDelete}>
                Delete
              </Button>
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    </Card>
  );
};
