import { useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { DialogService } from "../services/dialog";

export function useDialog() {
  const toast = useToast();
  const [dialogService] = useState<DialogService>(
    new DialogService((options: object) => {
      toast(options);
    })
  );
  return dialogService;
}
