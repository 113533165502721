import { LocalImages } from '../assets/images';

export const base64ToSrc = (base64: string) => {
  if (!isBase64Image(base64)) {
    return LocalImages.NotFound;
  }

  const binaryString = atob(base64);
  const len = binaryString.length;
  const arrayBuffer = new ArrayBuffer(len);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < len; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
  return URL.createObjectURL(blob);
};

const isBase64Image = (str: string) => {
  if (typeof str !== 'string') {
    return false;
  }
  const regex =
    /^(?:[a-zA-Z0-9+\/]{4})*(?:|(?:[a-zA-Z0-9+\/]{3}=)|(?:[a-zA-Z0-9+\/]{2}==)|(?:[a-zA-Z0-9+\/]{1}===))$/;
  return regex.test(str);
};
