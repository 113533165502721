import { Auth0Provider } from '@auth0/auth0-react';
import { ChakraProvider } from '@chakra-ui/react';
import '@mantine/carousel/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configuration } from './configuration';
import './index.scss';
import { Kernel } from './Kernel';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={configuration.userRegistry.domain}
      clientId={configuration.userRegistry.spaClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: configuration.userRegistry.audience,
      }}
    >
      <MantineProvider>
        <BrowserRouter>
          <ChakraProvider>
            <Kernel />
          </ChakraProvider>
        </BrowserRouter>
      </MantineProvider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
