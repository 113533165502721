import {
  Button,
  Flex,
  FlexProps,
  Image,
  MantineStyleProp,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { FaPhoneAlt } from 'react-icons/fa';
import { RegisterCredentials, SignupForm } from './SignupForm/SignupForm';

export interface SignupProps extends FlexProps {
  logo?: string;
  companyName?: string;
  contactUsMessage?: string;
  termsAgreementMessage?: string;
  passwordRegex?: RegExp;
  passwordErrorMessage?: string;
  formContainerStyles?: MantineStyleProp;
  isLoading?: boolean;
  failedSignInMessage?: string;
  onContactUsClick?: () => void;
  onSignupClick?: (registerCredentials: RegisterCredentials) => void;
  onSignInWithGoogleClick?: () => void;
  onBackToSignInClick?: () => void;
}

export const Signup = (props: SignupProps) => {
  return (
    <Flex justify={'center'} align={'center'} w={'100%'} h="100vh" {...props}>
      <Paper
        maw="30rem"
        p={'1.5rem 2.5rem'}
        shadow={'xl'}
        radius={'md'}
        style={props.formContainerStyles ?? {}}
      >
        <Stack gap={'2rem'}>
          <Stack align="center">
            {props.logo && (
              <Image
                className="LogoImage"
                maw="12rem"
                mah="5rem"
                fit="contain"
                src={props.logo ?? ''}
                alt={props.companyName ?? ''}
              />
            )}
          </Stack>
          <SignupForm
            isLoading={props.isLoading}
            passwordRegex={props.passwordRegex}
            passwordErrorMessage={props.passwordErrorMessage}
            failedSignInMessage={props.failedSignInMessage}
            onRegisterClick={registerCredentials =>
              props.onSignupClick?.(registerCredentials)
            }
            onSignInWithGoogleClick={() => props.onSignInWithGoogleClick?.()}
            onBackToSignInClick={() => props.onBackToSignInClick?.()}
          />
          <Stack gap="0.5" align="center">
            <Text size="sm" color="muted">
              {props.contactUsMessage ?? 'Having trouble logging in?'}
            </Text>
            <Button
              variant="transparent"
              leftSection={<FaPhoneAlt />}
              size="sm"
              onClick={() => props.onContactUsClick?.()}
            >
              Contact us
            </Button>
          </Stack>
          <Text size="xs" color="subtle" ta="center">
            {props.termsAgreementMessage ??
              'By continuing, you acknowledge that you have read, understood, and agree to our terms and condition'}
          </Text>
        </Stack>
      </Paper>
    </Flex>
  );
};
