import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalImages } from '../../assets/images';
import { Signup } from '../../components/Signup/Signup';
import { RegisterCredentials } from '../../components/Signup/SignupForm/SignupForm';
import { authentication } from '../../services/authentication';

export const SignupPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const handleSignInWithGoogle = async () => {
    await authentication.loginWithGoogle();
  };

  const handleRegister = async (registerCredentials: RegisterCredentials) => {
    try {
      setError(undefined);
      setIsLoading(true);
      await authentication.createWithUserAndPassword(
        registerCredentials.email,
        registerCredentials.password
      );
    } catch (e: any) {
      console.error(e);
      setError('There was an error creating your account. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Signup
      isLoading={isLoading}
      logo={LocalImages.CosmosRoboticsLogo}
      failedSignInMessage={error}
      onSignupClick={registerCredentials => handleRegister(registerCredentials)}
      onSignInWithGoogleClick={() => handleSignInWithGoogle()}
      onBackToSignInClick={() => navigate('/login')}
    />
  );
};
