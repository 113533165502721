import { Camera } from '../../data/camera-data';
import { authentication } from '../authentication';
import { BaseService } from './base-service';
import { BearerTokenApiClient } from './bearer-token-api-client';

class CustomerService extends BaseService {
  public constructor(apiClient: BearerTokenApiClient) {
    super(apiClient, 'v1/customer');
  }

  public async getCameras(): Promise<Camera[]> {
    const organization = (authentication.getUser() as any)?.organization.orgId;
    const url = `${this.url(organization)}`;
    const response = await this.apiClient.get(url);
    return (response as any).content;
  }

  public async triggerImageCapture(cameraId: string): Promise<string> {
    const url = `${this.url(`trigger/${cameraId}`)}`;
    const response = await this.apiClient.post(url, {});
    return (response as any).content;
  }

  public async getLatestImage(cameraId: string): Promise<any> {
    const organization = (authentication.getUser() as any)?.organization.orgId;
    const url = `${this.url(`/last-image/${organization}/${cameraId}`)}`;
    const response = await this.apiClient.get(url);
    return (response as any).content;
  }

  public async createCamera(cameraId: string): Promise<any> {
    const organization = (authentication.getUser() as any)?.organization.orgId;
    const url = `${this.url(`create/${cameraId}/${organization}`)}`;
    const response = await this.apiClient.post(url, {
      id: cameraId,
      organization: organization,
    });
    return (response as any).content;
  }

  public async deleteCamera(cameraId: string): Promise<any> {
    const organization = (authentication.getUser() as any)?.organization.orgId;
    const url = `${this.url(`delete/${cameraId}/${organization}`)}`;
    const response = await this.apiClient.post(url, {
      id: cameraId,
      organization: organization,
    });
    return (response as any).content;
  }
}

export const customerService = new CustomerService(new BearerTokenApiClient());
