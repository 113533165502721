import barquilla from './barquilla.svg';
import cosmosHorizontalLogo from './cosmos-horizontal-logo.svg';
import cosmosRoboticsLogo from './cosmos-robotics-logo.svg';
import cosmosSmallLogo from './cosmos-small-logo.svg';
import notFound from './not-found.svg';

export const LocalImages = {
  CosmosRoboticsLogo: cosmosRoboticsLogo,
  CosmosSmallLogo: cosmosSmallLogo,
  CosmosHorizontalLogo: cosmosHorizontalLogo,
  DefaultProductImage: barquilla,
  NotFound: notFound,
};
