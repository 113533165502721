import {
  ActionIcon,
  Flex,
  FlexProps,
  Group,
  Image,
  MantineStyleProp,
  Text,
} from '@mantine/core';
import { FunctionComponent, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import { NavbarLink, NavLink } from './components/NavbarLink/NavbarLink';

import { LocalImages } from '../../../assets/images';
import './SideBar.scss';

export interface SideBarProps extends FlexProps {
  links: NavLink[];
  defaultItemSlug?: string;
  selectedStyles?: MantineStyleProp;
  expanded?: boolean;
  onItemSelected?: (slug: string) => void;
  onMenuClick?: () => void;
}

export const SideBar: FunctionComponent<SideBarProps> = props => {
  const [activeLink, setActiveLink] = useState<string>(
    props.defaultItemSlug ?? ''
  );

  const handleClick = (slug: string) => {
    setActiveLink(slug);
    props.onItemSelected?.(slug);
  };

  return (
    <Flex
      classNames={{ root: 'SideBar' }}
      direction={'column'}
      h={'100%'}
      w={'100%'}
      bg={'#EFF4F6'}
      color={'#fff'}
      fz={'1.5rem'}
      gap={'lg'}
      pt={'sm'}
      {...props}
    >
      <Flex w={'100%'} justify={'center'} gap={'0.5rem'}>
        <Image
          src={
            props.expanded
              ? LocalImages.CosmosHorizontalLogo
              : LocalImages.CosmosSmallLogo
          }
        />
        {props.expanded && (
          <ActionIcon
            variant="transparent"
            color="#1F374E"
            size={'md'}
            onClick={() => props.onMenuClick?.()}
          >
            <MdMenu size={'24px'} />
          </ActionIcon>
        )}
      </Flex>
      {props.expanded && (
        <Group justify="flex-start" pl={'xl'}>
          <Text size="xs" fw={500} c="dimmed">
            Menu
          </Text>
        </Group>
      )}
      {props.links?.map((link, index) => {
        return (
          <NavbarLink
            link={link}
            key={index}
            active={link.slug === activeLink}
            expanded={props.expanded}
            onClick={() => handleClick(link.slug)}
          />
        );
      })}
    </Flex>
  );
};
