import { Button, Flex, Loader, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { authentication } from '../../services/authentication';

const Page = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const completeLogin = async () => {
      try {
        await authentication.handleRedirectCallback();
        navigate('/home');
      } catch (err: any) {
        setError(err);
        console.error(err);
      }
    };
    completeLogin();
  }, [navigate]);

  return (
    <div>
      {error?.error === 'access_denied' ? (
        <Flex
          direction={'column'}
          justify={'center'}
          align={'center'}
          gap={'md'}
        >
          <Title>We Need One More Step</Title>
          <Text>{error.errorDescription}</Text>
          <Button
            variant="light"
            onClick={() => navigate('/login')}
            leftSection={<IoMdArrowRoundBack />}
          >
            Back to Login
          </Button>
        </Flex>
      ) : (
        <Loader size="xl" />
      )}
    </div>
  );
};

export const LoginCallbackPage = Page;
