import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { BiHomeSmile } from 'react-icons/bi';
import { Outlet, useNavigate } from 'react-router-dom';

import { Layout } from '../../components/Layout/Layout/Layout';
import { NavLink } from '../../components/Layout/SideBar/components/NavbarLink/NavbarLink';
import { SideBar } from '../../components/Layout/SideBar/SideBar';
import { ToolBar } from '../../components/Layout/ToolBar/ToolBar';
import { authentication } from '../../services/authentication';

const defaultLinks: NavLink[] = [
  { label: 'Home', slug: 'home', icon: <BiHomeSmile size={'26px'} /> },
];
export const MainLayout = () => {
  const user = authentication.getUser();
  const navigator = useNavigate();
  const pathname = window.location.pathname.split('/');
  const tablet = useMediaQuery('(max-width: 51.25rem)');
  const mobile = useMediaQuery('(max-width: 25.875rem)');
  const [expanded, setExpanded] = useState<boolean>(tablet ? false : true);
  const [hidden, setHidden] = useState<boolean>(mobile ? true : false);

  useEffect(() => {
    setExpanded(tablet ? false : true);
    setHidden(mobile ? true : false);
  }, [tablet, mobile]);

  return (
    <Layout
      variant="FullSidebar"
      sidebarExpanded={expanded}
      sidebarHidden={hidden}
      isControlledSidebar
      sideBar={
        <SideBar
          links={defaultLinks}
          expanded={expanded}
          defaultItemSlug={pathname[pathname.length - 1]}
          onMenuClick={() => setExpanded(false)}
          onItemSelected={slug => navigator(slug)}
        />
      }
      toolBar={
        <ToolBar
          expanded={expanded}
          onMenuClick={() => setExpanded(true)}
          name={user?.name}
          organization={(user as any)?.organization.orgName ?? ''}
          profileImage={user?.picture}
          onLogoutClick={() => authentication.logout()}
        />
      }
    >
      <Outlet />
    </Layout>
  );
};
